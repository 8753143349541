<template>
  <div>
  <!-- BEGIN FOOTER -->
  <div class="footer-wrapper" style="
        padding: 10px;
        background: rgb(49, 49, 49);
        border-top-left-radius: 0px;
      ">
      <div class="footer-section f-section-1">
        <div class="row">
          <div class="col-3 text-center p-3">
            <img src="@/assets/images/logo-footer.png" height="80%"/>
          </div>
          <div class="col-8 p-3" style="font-size: small;">
            <p class="">อาคารศูนย์เรียนรู้สุขภาวะ</p>
            <p>
              เลขที่ 99/8 ซอยงามดูพลี แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพฯ 10120<br />
              โทร 099-2989424 (สุพัตรา อรุณนภา)
            </p>
            <!-- <p>ส่งเอกสาร/เชิญประชุม : esaraban@thaihealth.or.th</p> -->
          </div>
        </div>
      </div>
      <div class="footer-section f-section-2 p-3">
        <div class="col-6" style="float: right;">
          <a style="font-size: small;color:white;" class="row" href="https://web.facebook.com/thaihealth?_rdc=1&_rdr" target="_blank">
            <i class="fa-brands fa-facebook col-2" style="font-size: x-large;"></i> 
            <p class="col-10">สสส. (สํานักงานกองทุนสนับสนุน การสร้างเสริมสุขภาพ) : ThaiHealth</p>
          </a>
        </div>
      </div>
  </div>

  <div class="row text-center p-1">
    <p style="font-size: smaller;">สงวนสิทธิ์ สสส. สํานักงานกองทุนสนับสนุน การสร้างเสริมสุขภาพ | สอจร. สนับสนุนการป้องกันอุบัติเหตุจราจร</p>
  </div>
</div>
  <!-- END FOOTER -->
</template>
<style scoped>
.footer-wrapper .footer-section p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
}
</style>
