import { createStore } from "vuex";
import i18n from "../i18n";

const axios = require("axios");
export default new createStore({
  state: {
    layout: "app",
    is_show_sidebar: false,
    is_show_search: false,
    is_dark_mode: false,
    dark_mode: "light",
    locale: "th",
    menu_style: "vertical",
    layout_style: "full",
    countryList: [
      {
        code: "th",
        name: "ไทย",
      },
      {
        code: "en",
        name: "English",
      },
    ],
    user: {
      id: 0,
      username: "",
      email: "",
      user_type: 0,
      confirmed: false,
      blocked: false,
      phone: null,
      business_name: null,
      business_address: null,
      business_sub_district: null,
      business_district: null,
      business_province: null,
      business_zip_code: null,
      business_latitude: null,
      business_longitude: null,
      business_branch: null,
      business_addition_details: null,
      join_in_years: 0,
      role: {
        id: 0,
        name: null,
        description: null,
        type: "",
        createdAt: null,
        updatedAt: null,
      },
    },
    menu: {
      type0: true,
      type1: false,
      type2: false,
    },
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
    toggleSideBar(state, value) {
      state.is_show_sidebar = value;
    },
    toggleSearch(state, value) {
      state.is_show_search = value;
    },
    toggleLocale(state, value) {
      value = value || "th";
      i18n.global.locale = value;
      localStorage.setItem("i18n_locale", value);
      state.locale = value;
    },

    toggleDarkMode(state, value) {
      //light|dark|system
      value = value || "light";
      localStorage.setItem("dark_mode", value);
      state.dark_mode = value;
      if (value == "light") {
        state.is_dark_mode = false;
      } else if (value == "dark") {
        state.is_dark_mode = true;
      } else if (value == "system") {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          state.is_dark_mode = true;
        } else {
          state.is_dark_mode = false;
        }
      }

      if (state.is_dark_mode) {
        document.querySelector("body").classList.add("dark");
      } else {
        document.querySelector("body").classList.remove("dark");
      }
    },

    toggleMenuStyle(state, value) {
      //horizontal|vertical|collapsible-vertical
      value = value || "";
      localStorage.setItem("menu_style", value);
      state.menu_style = value;
      if (!value || value === "vertical") {
        state.is_show_sidebar = true;
      } else if (value === "collapsible-vertical") {
        state.is_show_sidebar = false;
      }
    },

    toggleLayoutStyle(state, value) {
      //boxed-layout|large-boxed-layout|full
      value = value || "";
      localStorage.setItem("layout_style", value);
      state.layout_style = value;
    },

    register(state, value) {
      console.log(state);
    },

    login(state, value) {
      if (value.username.trim() == "" || value.password.trim() == "") {
        new window.Swal({
          title: "Invalid username or password.",
          padding: "2em",
          icon: "error",
          dangerMode: true,
        });
        return;
      }

      const FormData = require("form-data");
      let data = new FormData();
      data.append("identifier", value.username);
      data.append("password", value.password);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.ปันความปลอดภัย.com/api/auth/local",
        headers: data.headers,
        data: data,
        withCredentials: true
      };

      axios(config)
        .then((response) => {
          const data_login = JSON.parse(JSON.stringify(response.data));
          state.token = data_login.jwt;
          localStorage.setItem("appToken", `${btoa(state.token)}`);
          this.commit("getUserInformation");
          // window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
          new window.Swal({
            title: "Invalid username or password.",
            padding: "2em",
            icon: "error",
            dangerMode: true,
          });
        });
    },

    logout(state, payload) {
      localStorage.removeItem("appToken");
      state.user = {
        id: 0,
        username: "",
        email: "",
        user_type: 0,
        confirmed: false,
        blocked: false,
        phone: null,
        business_name: null,
        business_address: null,
        business_sub_district: null,
        business_district: null,
        business_province: null,
        business_zip_code: null,
        business_latitude: null,
        business_longitude: null,
        business_branch: null,
        business_addition_details: null,
        join_in_years: 0,
        role: {
          id: 0,
          name: null,
          description: null,
          type: "",
          createdAt: null,
          updatedAt: null,
        },
      }
      const toast = window.Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        padding: "2em",
      });
      toast.fire({
        icon: "warning",
        title: "Signed out",
        padding: "2em",
      });
    },

    async getUserInformation(state, value) {
      try {
        var token = `${atob(localStorage.getItem("appToken"))}`;
      } catch (e) {
        localStorage.removeItem("appToken");
        state.user = {
          id: 0,
          username: "",
          email: "",
          user_type: 0,
          confirmed: false,
          blocked: false,
          phone: null,
          business_name: null,
          business_address: null,
          business_sub_district: null,
          business_district: null,
          business_province: null,
          business_zip_code: null,
          business_latitude: null,
          business_longitude: null,
          business_branch: null,
          business_addition_details: null,
          join_in_years: 0,
          role: {
            id: 0,
            name: null,
            description: null,
            type: "",
            createdAt: null,
            updatedAt: null,
          },
        }
        return;
      }
      // Get the user's profile information, including their role
      await axios
        .get("https://api.ปันความปลอดภัย.com/api/users/me?populate=*", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          state.user = JSON.parse(JSON.stringify(response.data));

          if (state.user.role.type == "authenticated_lv1") {
            state.menu.type0 = false;
            state.menu.type1 = true;
            state.menu.type2 = false;
          } else if (state.user.role.type == "authenticated_lv2") {
            state.menu.type0 = false;
            state.menu.type1 = false;
            state.menu.type2 = true;
          } else {
            state.menu.type0 = true;
            state.menu.type1 = false;
            state.menu.type2 = false;
          }
          // console.log(state.user);
          if (value?.show_noti) {
            const toast = window.Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              padding: "2em",
            });
            toast.fire({
              icon: "success",
              title: "Signed in successfully",
              padding: "2em",
            });
          }
        })
        .catch((error) => {
          localStorage.removeItem("appToken");
          state.user = {
            id: 0,
            username: "",
            email: "",
            user_type: 0,
            confirmed: false,
            blocked: false,
            phone: null,
            business_name: null,
            business_address: null,
            business_sub_district: null,
            business_district: null,
            business_province: null,
            business_zip_code: null,
            business_latitude: null,
            business_longitude: null,
            business_branch: null,
            business_addition_details: null,
            join_in_years: 0,
            role: {
              id: 0,
              name: null,
              description: null,
              type: "",
              createdAt: null,
              updatedAt: null,
            },
          }
          if (value?.show_noti) {
            const toast = window.Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              padding: "2em",
            });
            toast.fire({
              icon: "warning",
              title: "Signed out",
              padding: "2em",
            });
          }
        });
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
  },
  actions: {},
  modules: {},
});
