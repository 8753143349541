import { createRouter, createWebHistory } from "vue-router";

// import Home from '../views/index.vue'; **** อันนี้คือหน้าหลัก ของ templat
import store from "../store";

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
  // homepage & dashboard
  {
    path: "/",
    name: "Home",
    component: () => import("../views/homepage.vue"),
  },

  {
    path: "/public_road_safety",
    name: "Public_road_safety",
    component: () => import("../views/public_road_safety.vue"),
  },
  {
    path: "/km",
    name: "km",
    component: () => import("../views/km/km_read.vue"),
  },
  // reset password
  {
    path: "/send-email-reset-password",
    name: "SendEmailResetPassword",
    component: () => import("../views/reset_password_send_email.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/reset_password.vue"),
    meta: {
      layout: "auth",
    },
  },

  // confirm email
  {
    path: "/email-confirmation",
    name: "Confirm_email",
    component: () => import("../views/confirm_email.vue"),
    meta: {
      layout: "app",
    },
  },
  // login
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/loginpage.vue"),
    meta: {
      layout: "auth",
    },
  },

  // admin
  {
    path: "/main/thaihealth",
    name: "main",
    component: () => import("../views/main.vue"),
  },
  {
    path: "/main/dashboard-admin",
    name: "dashboard_admin",
    component: () => import("../views/management/dashboard_admin.vue"),
    meta: { layout: "Home" },
  },

  // approve
  {
    path: "/main/thaihealth/management/management_list",
    name: "management_list",
    component: () => import("../views/management/management_list.vue"),
  },

  // company and industry
  {
    path: "/main/company-industry",
    name: "dashboard",
    component: () => import("../views/dashboard.vue"),
    meta: { layout: "Home" },
  },

  // estimate form
  {
    path: "/main/company-industry/estimate_form",
    name: "estimate_form_list",
    component: () => import("../views/estimate_form/estimate_form_list.vue"),
  },
  {
    path: "/main/company-industry/estimate_form/add",
    name: "estimate_form_add",
    component: () => import("../views/estimate_form/estimate_form_add.vue"),
  },
  {
    path: "/main/company-industry/estimate_form/edit",
    name: "estimate_form_edit",
    component: () => import("../views/estimate_form/estimate_form_edit.vue"),
  },

  // register

  {
    path: "/register-select-user",
    name: "register-select-user",
    component: () => import("../views/register/register_select_user.vue"),
  },
  {
    path: "/register-company",
    name: "register-company",
    component: () => import("../views/register/register_company.vue"),
  },
  {
    path: "/register-industry",
    name: "register-industry",
    component: () => import("../views/register/register_industry.vue"),
  },
  {
    path: "/register-education",
    name: "register-education",
    component: () => import("../views/register/register_educational_institution.vue"),
  },
  {
    path: "/register-government",
    name: "register-government",
    component: () => import("../views/register/register_government_unit.vue"),
  },
  {
    path: "/register-success",
    name: "register-success",
    component: () => import("../views/register/register_success.vue"),
  },
  {
    path: "/user-data",
    name: "user-data",
    component: () => import("../views/register/user_update_data.vue"),
  },
];

const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          el: to.hash,
          top: 140,
          behavior: "smooth",
        };
      }
      return {
        left: 0,
        top: 0,
      };
    }
  },
});
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(async (to, from) => {
  // let is_authenticated = store.state.user.role.type !== "";

  // if (is_authenticated && to.name === "Login") {
  //   router.push({ name: "Home" });
  // }

  // if ( !is_authenticated &&
  //   (to.name === "main" ||
  //     to.name === "management_list" ||
  //     to.name === "dashboard" ||
  // to.name === "dashboard_admin" ||
  //     to.name === "estimate_form_list" ||
  //     to.name === "estimate_form_add" ||
  //     to.name === "estimate_form_edit")
  // ) {
  //   router.push({ name: "Home" });
  // }

  // if (to.path === "/logout") {
  //   store.commit("logout");
  //   if (from.name === "Home") window.location.reload();
  //   router.push({ name: "Home" });
  // }

  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.layout && to.meta.layout == "auth") {
    store.commit("setLayout", "auth");
  } else {
    store.commit("setLayout", "app");
  }
  const is_authenticated = store.state.user.role.type !== "";
  if (
    !is_authenticated &&
    (to.name === "main" ||
      to.name === "management_list" ||
      to.name === "dashboard" ||
      to.name === "user-data" ||
      to.name === "estimate_form_list" ||
      to.name === "estimate_form_add" ||
      to.name === "estimate_form_edit")
  ) {
    // return { name: "Home" };
    router.push({ name: "Home" });
  }
  if (!store.state.menu.type2 && (to.name === "management_list" || to.name === "main")) {
    // return { name: "Home" };
    router.push({ name: "Home" });
  }
  if (to.path === "/logout") {
    store.commit("logout");
    if (from.name === "Home") window.location.reload();
    // return { name: "Home" };
    router.push({ name: "Home" });
  }

  next(true);
});

export default router;
