<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <div class="header navbar navbar-expand-sm" style="
                  background: #fff;
                  border-bottom: 2px solid rgb(243, 244, 246);
                  height: 42px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  padding-left: 5px;
                ">
        <div class="dropdown nav-item language-dropdown btn-group">
          <!-- <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false"
            class="btn dropdown-toggle btn-icon-only nav-link">
            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width"
              alt="flag" />
            &nbsp;&nbsp;
            <span v-if="selectedLang" style="font-size: 16px; color: #ee641a">
              {{ selectedLang.name }}
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-ddllang" aria-labelledby="ddllang">
            <perfect-scrollbar>
              <li v-for="item in countryList" :key="item.code">
                <a href="javascript:;" class="dropdown-item d-flex align-items-center"
                  :class="{ active: $i18n.locale === item.code }" @click.prevent="changeLanguage(item)">
                  <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" />
                  <span>{{ item.name }}</span>
                </a>
              </li>
            </perfect-scrollbar>
          </ul> -->
        </div>
        <div class="navbar-item flex-row ms-md-auto">
          <div class="nav-item" style="margin: 0 20px 0 16px">
            <router-link to="/login" v-if="$store.state.user.role.type == ''">
              <img src="@/assets/images/user-avtar.svg"
                style="height: 24px; max-height: 32px; color: #ee641a;filter: invert(43%) sepia(63%) saturate(3184%) hue-rotate(356deg) brightness(102%) contrast(87%);"
                alt="avatar" />
              <span style="font-size: 16px; color: #ee641a"> เข้าสู่ระบบ </span>
            </router-link>
            <div class="dropdown nav-item user-profile-dropdown btn-group" v-else>
              <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
                class="btn dropdown-toggle btn-icon-only user nav-link">
                <img src="@/assets/images/user-avtar.svg"
                  style="height: 24px; max-height: 32px; color: #ee641a;filter: invert(43%) sepia(63%) saturate(3184%) hue-rotate(356deg) brightness(102%) contrast(87%);"
                  alt="avatar" />
                <span style="color: #ee641a">{{ $store.state.user.email }} <span
                    v-if="$store.state.user.role.type == 'authenticated_lv0'"> (รออนุมัติ)</span></span>
              </a>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                <li role="presentation">
                  <router-link to="/user-data" class="dropdown-item">
                    <img src="@/assets/images/user-avtar.svg"
                    style="height: 24px; max-height: 24px; color: #B1B5C6;"
                    alt="avatar" />
                    Profile
                  </router-link>
                </li>
                <li role="presentation">
                  <router-link to="/logout" class="dropdown-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-log-out">
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    Sign Out
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header class="header navbar navbar-expand-sm" style="background: #fff; height: 90px; max-height: fit-content">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item" style="height: 54px; max-height: 54px">
            <router-link to="/">
              <img src="@/assets/images/logo-app.jpg" style="height: 54px" class="navbar-logo" alt="logo" />
            </router-link>
          </li>
        </ul>
        <div class="navbar-item flex-row ms-md-auto">
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type0">
            <router-link to="#homepage_statistics">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                สถิติอุบัติเหตุทางถนน
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type0">
            <router-link to="#homepage_km">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                คู่มือ
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type1">
            <router-link to="/">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                หน้าหลัก
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type1">
            <router-link to="/main/company-industry">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                แดชบอร์ด
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type1">
            <router-link to="/main/company-industry/estimate_form">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                บันทึกข้อมูลมาตรการ<br>องค์กรด้าน Road Safety
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type1">
            <router-link :to="'/public_road_safety?id=' + store.state.user.id">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                ภาพรวมมาตรการด้าน<br>Road Safety
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type2">
            <router-link to="/">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                หน้าหลัก
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type2">
            <router-link to="/main/thaihealth">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                แดชบอร์ด
              </p>
            </router-link>
          </div>
          <div class="nav-item" style="margin: 0 20px 0 16px" v-if="$store.state.menu.type2">
            <router-link to="/main/thaihealth/management/management_list">
              <p style="
                          color: rgb(18, 143, 102);
                          font-size: 16px;
                          text-align: center;
                        ">
                จัดการบัญชีผู้ใช้
              </p>
            </router-link>
          </div>
          <!--
          <div class="nav-item" style="margin: 0 20px 0 16px">
            <router-link to="/">
              <p style="
                    color: rgb(18, 143, 102);
                    font-size: 16px;
                    text-align: center;
                  ">
                ติดต่อ
              </p>
            </router-link>
          </div> -->
        </div>
      </header>
    </div>
    <!--  END NAVBAR  -->
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
const store = useStore();

const selectedLang = ref(null);
const countryList = ref(store.state.countryList);
const i18n = useI18n();

onMounted(() => {
  selectedLang.value = window.$appSetting.toggleLanguage();
  toggleMode();
});

const toggleMode = (mode) => {
  window.$appSetting.toggleMode(mode);
};

const changeLanguage = (item) => {
  selectedLang.value = item;
  window.$appSetting.toggleLanguage(item);

  i18n.locale.value = item.code;
};
</script>

<style scoped>
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu-ddllang {
  right: auto !important;
}
</style>
